export interface OTPRequest {
  numeroCelular: string;
  token: string;
  payload: PayloadOTPVerificacion
}


export interface PayloadOTPVerificacion {
  correo: string;
  medioOTP: tipoMediosOTP;
  statusCliente: tipoStatusCliente,
  idTransaccion?: string;
}


export enum tipoMediosOTP {
  SMS = 'SMS',
  EMAIL = 'EMAIL'
}

export enum tipoStatusCliente {
  NO_REGISTRADO = 0,
  REGISTRADO = 1
}
