import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  constructor() { }

  sendEvent(event: any) {
    try {
      const dataLayer =  (window as any).dataLayer;

      if (dataLayer && typeof (dataLayer['push']) == 'function') {
        dataLayer.push(event);
        console.log('Evento enviado correctamente');
        console.log(event);
      }
    } catch (error) {
      console.error('Hubo un error en el evento');
      console.log(event);
    }
  }
}
