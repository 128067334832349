import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalHTMLService {

  constructor( private http: HttpClient, private domSanitizer: DomSanitizer ) { }

  getTYC(): Observable<SafeHtml> {
    return this.http.get('assets/pages/tyc.html', { responseType: 'text' }).pipe(
      map(( data ) => this.domSanitizer.bypassSecurityTrustHtml(data) )
    )
  }

  getAvisoPrivacidad(): Observable<SafeHtml> {
    return this.http.get(`assets/pages/aviso_privacidad.html`, { responseType: 'text' }).pipe(
      map(( data ) => this.domSanitizer.bypassSecurityTrustHtml(data) )
    );
  }

  getBureauTerms(): Observable<SafeHtml> {
    return this.http.get('assets/pages/buro.html', { responseType: 'text' }).pipe(
      map(( data ) => this.domSanitizer.bypassSecurityTrustHtml(data) )
    );
  }
}
