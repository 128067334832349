import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';

import { ConfirmDialogConfig, ToastConfig, severityToast } from '../interfaces';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  configuracionErrorToast: ToastConfig = {
    key: 'toastMsg',
    severity: severityToast.error,
    summary: '',
    detail: '',
    sticky: false
  }

  configuracionInfoToast: ToastConfig = {
    ...this.configuracionErrorToast,
    severity: severityToast.info
  }

  configuracionSuccessToast = {
    ...this.configuracionInfoToast,
    severity: severityToast.success
  }

  configuracionWarningToast = {
    ...this.configuracionInfoToast,
    severity: severityToast.warm
  }

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private messageService: MessageService
    )
    { }


  showDialog({ acceptFunction, rejectFunction, ...rest }: ConfirmDialogConfig
  ){
    this.confirmationService.confirm({
      ...rest,
      accept: acceptFunction,
      reject: rejectFunction
  });
  }

  public navigateTo(route: string, queryParam: string){
    this.router.navigate([route], { queryParams: { path: queryParam } });
  }


  showToast(toast: ToastConfig): void {
    this.messageService.add(toast);
  }

  clearToast(toast: string | undefined = undefined): void {
    this.messageService.clear(toast);
  }
}
