export interface ToastConfig {
  key: string;
  severity:  severityToast,
  summary: string;
  detail: string;
  icon?: string ;
  sticky?: boolean;
  closable?: boolean;
}


export enum severityToast {
  custom = 'custom',
  error = 'error',
  info = 'info',
  success = 'success',
  warm = 'warn'
}
