import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { manejarError } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  constructor( private http: HttpClient ) { }

  getMyIP(): Observable<any> {
    return this.http.get(`https://api.ipify.org/?format=json`).pipe(
      map((response: any) => {
        return response.ip
      }),
      catchError(() => manejarError('No se pudo obtener tu IP, verifique su conexión de internet'))
    )
  }
}
